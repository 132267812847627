import { render, staticRenderFns } from "./Rullet.vue?vue&type=template&id=38d6708e&scoped=true&"
import script from "./Rullet.vue?vue&type=script&lang=js&"
export * from "./Rullet.vue?vue&type=script&lang=js&"
import style0 from "./Rullet.vue?vue&type=style&index=0&id=38d6708e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38d6708e",
  null
  
)

export default component.exports